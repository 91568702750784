import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import HeaderImage from "../../assets/img/header-img.png";

export default function Pricing() {
  return (
    <Wrapper id="about">
      <div className="lightBg" style={{ padding: "100px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">About Us</h1>
            <MissionContent>
              <p className="font16">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                <br />
                labore et dolore magna aliquyam erat, sed diam voluptua.
              </p>
            </MissionContent>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
            <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
                Adriano
            </TableBox>
            <TableBox>
            <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
                Flávio
            </TableBox>
            <TableBox>
            <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
                Gonçalo
            </TableBox>
            <TableBox>
            <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}} />
                João
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;

const MissionContent = styled.section`
  padding-top: 15px;
`;

const Img = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 560px) {
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  overflow: hidden;
  width: 20%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




