import * as React from "react";
import headerLogo from "../../assets/img/mhl.png";
import headerLogoBlack from "../../assets/img/mhl-footer.png";

function SvgComponent(props) {
  return (
    <img src={props.footer ? headerLogoBlack : headerLogo} style={{ height: "60px" }}></img>
  );
}

export default SvgComponent;
