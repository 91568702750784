import * as React from "react";

function SvgComponent(props) {
  return (
    <svg fill="#000000" width={39.581} height={39.58} version="1.1" id="Layer_1"
	 viewBox="0 0 511.999 511.999">
<g>
	<g>
		<path d="M171.324,360.669c-1.681-2.483-3.712-4.762-5.982-6.784c1.152-1.451,2.15-2.953,3.021-4.523
			c3.072-5.197,4.625-11.153,4.625-17.647c0-9.796-3.149-18.304-9.378-25.301c-6.929-7.723-16.179-11.648-27.426-11.648h-33.297
			c-7.023,0-12.979,2.577-17.229,7.475c-3.627,4.25-5.478,9.472-5.478,15.522v81.451c0,5.948,1.852,11.128,5.521,15.428
			c4.198,4.847,10.146,7.424,17.178,7.424h35.55c12.271,0,22.246-4.224,29.696-12.553c6.596-7.373,9.95-16.452,9.95-26.948
			C178.074,374.288,175.77,366.89,171.324,360.669z M106.394,320.059h28.902c5.052,0,7.279,1.877,8.627,3.422
			c1.971,2.125,2.876,4.702,2.876,8.073c0,3.226-0.922,5.777-3.004,8.073c-2.074,2.372-4.702,3.422-8.499,3.422h-28.902V320.059z
			 M148.412,392.61c-2.475,2.825-5.675,4.147-10.129,4.147h-31.898v-28.544h31.147c4.625,0,8.021,1.374,10.701,4.275
			c2.5,2.748,3.652,5.948,3.652,10.078C151.893,386.739,150.793,389.913,148.412,392.61z"/>
	</g>
</g>
<g>
	<g>
		<path d="M207.787,295.534c-5.001-4.42-12.467-4.625-18.022,0.35c-1.749,1.749-3.823,4.847-3.823,9.728v105.754
			c0,3.746,1.323,7.074,4.198,9.924c2.449,2.176,5.521,3.328,8.9,3.328c3.277,0,6.298-1.152,8.798-3.379
			c2.773-2.5,4.301-5.999,4.301-9.873V305.612C212.139,301.636,210.611,298.086,207.787,295.534z"/>
	</g>
</g>
<g>
	<g>
		<path d="M323.516,313.233c-10.65-13.943-25.728-21.018-44.826-21.018c-19.2,0-34.321,7.074-45.022,21.103
			c-8.875,11.947-13.372,27.127-13.372,45.099c0,17.997,4.497,33.126,13.397,44.996c10.65,14.071,25.796,21.197,44.996,21.197
			c19.098,0,34.202-7.125,44.8-21.154c9.122-11.853,13.747-27.025,13.747-45.047S332.612,325.179,323.516,313.233z M303.061,388.087
			c-5.572,7.646-13.551,11.375-24.371,11.375c-10.897,0-18.876-3.703-24.328-11.324c-5.222-7.296-7.876-17.297-7.876-29.722
			c0-12.527,2.628-22.502,7.876-29.722c5.453-7.629,13.423-11.324,24.328-11.324c10.829,0,18.799,3.729,24.371,11.375
			c5.299,7.279,7.979,17.271,7.979,29.679C311.04,370.739,308.343,380.74,303.061,388.087z"/>
	</g>
</g>
<g>
	<g>
		<path d="M498.21,262.28c-8.004,0.623-20.855-9.856-33.28-19.985c-7.074-5.777-14.882-12.1-23.287-17.638
			c19.217-6.127,35.285-17.135,47.198-34.15c32.973-47.053,24.764-120.977,11.836-156.527c-1.562-4.25-5.291-7.373-9.839-8.201
			l-2.159-0.179c-3.772,0-7.322,1.621-9.754,4.446c-8.286,9.677-31.223,13.722-53.478,17.647
			c-34.825,6.161-79.351,14.464-100.992,49.835c-13.5-24.499-37.112-44.041-57.976-53.769c-4.122-1.929-8.951-1.51-12.698,1.067
			c-3.678,2.577-5.828,6.955-5.47,11.52c0.623,8.004-9.856,20.847-19.985,33.28c-21.419,26.223-50.731,62.157-17.331,103.919
			c10.803,13.491,23.936,21.598,38.229,24.439c1.118,3.985,2.842,8.141,4.949,12.407H128C57.31,230.399,0,287.709,0,358.399
			s57.31,128,128,128h153.6c70.69,0,128-57.31,128-128c0-3.311-0.725-6.417-0.973-9.66c7.842,0.93,15.087,0.674,21.18-0.401
			c36.941-6.511,67.874-39.748,80.99-67.891c1.929-4.122,1.51-8.951-1.067-12.698C507.145,264.072,502.775,261.922,498.21,262.28z
			 M337.562,137.079c5.402-48.802,47.607-56.252,92.331-64.154c19.098-3.379,37.325-6.579,51.575-13.602
			c8.61,36.702,7.91,85.803-13.585,116.497c-13.449,19.2-34.014,28.954-61.141,28.954c-19.328,0-36.463-5.897-49.135-16.777
			c9.66-7.296,20.659-13.278,32.777-17.749l2.697-1.05c22.059-7.697,42.684-7.501,44.297-7.125l2.748,0.299
			c6.025,0,11.162-4.096,12.501-10.001c1.263-5.7-1.502-11.375-6.409-14.046c12.015-10.846,21.513-23.45,28.373-37.649
			c1.476-3.072,1.673-6.554,0.546-9.779c-1.126-3.226-3.447-5.828-6.528-7.296c-6.11-2.901-14.123-0.154-17.084,5.948
			c-10.197,21.129-27.221,37.299-51.797,49.126c1.51-10.675-0.154-21.274-4.975-30.925c-3.046-6.153-11.187-8.678-17.135-5.751
			c-3.072,1.527-5.359,4.147-6.451,7.398c-1.101,3.277-0.836,6.75,0.691,9.779c7.697,15.428-2.022,29.5-5.786,34.074
			c-8.422,4.198-16.358,9.097-23.697,14.626C338.014,158.378,336.35,147.899,337.562,137.079z M339.046,205.738
			c7.586,6.903,16.512,12.16,26.138,16.222c-1.391,0.981-2.773,1.86-4.173,2.987c-8.055,6.468-13.935,13.841-18.15,21.717
			c-6.827-3.755-13.961-6.852-21.478-9.327c0.034-0.102,0.145-0.145,0.179-0.247C325.487,225.638,331.358,215.116,339.046,205.738z
			 M322.773,185.676c-11.409,12.672-19.951,27.153-25.438,43.128c-0.358,1.041-0.324,2.091-0.401,3.149
			c-4.079-0.503-8.115-1.143-12.305-1.246c-3.823-5.026-6.818-9.702-8.687-14.003c11.426-3.328,22.545-9.813,32.751-20.019
			c4.966-4.966,8.841-9.924,12.228-14.839C321.579,183.108,322.048,184.447,322.773,185.676z M252.134,170.359
			c-1.792,6.69-3.49,13.483-4.471,20.531c-5.939-2.645-11.605-6.997-16.674-13.338c-19.106-23.885-6.255-43.025,17.169-71.757
			c8.474-10.385,16.674-20.42,21.47-30.805c18.534,12.715,35.78,32.879,39.544,54.221c3.055,17.374-3.191,33.988-18.569,49.365
			c-5.555,5.555-11.29,9.54-17.024,12.075c0.819-4.275,1.98-8.747,3.302-13.688c3.883-14.558,8.277-31.053,5.683-53.06
			c-0.828-7.014-7.194-12.041-14.217-11.204c-7.006,0.862-12.032,7.219-11.204,14.217
			C259.166,144.051,255.59,157.422,252.134,170.359z M281.6,460.799H128c-56.465,0-102.4-45.935-102.4-102.4
			s45.935-102.4,102.4-102.4h153.6c56.465,0,102.4,45.935,102.4,102.4S338.065,460.799,281.6,460.799z M425.353,323.131
			c-7.407,1.306-14.652,0.725-21.734-1.425c-2.918-9.694-6.835-18.867-11.844-27.443c11.145,2.415,23.637,4.053,38.886,2.253
			c7.023-0.828,12.041-7.194,11.204-14.217c-0.862-7.006-7.219-12.032-14.217-11.204c-17.135,2.022-30.515-1.553-43.443-5.009
			c-6.323-1.69-12.774-3.217-19.396-4.207c-0.282-0.239-0.572-0.469-0.862-0.708c2.662-5.76,6.878-11.281,13.056-16.213
			c23.885-19.106,43.025-6.255,71.757,17.161c10.385,8.474,20.42,16.674,30.805,21.47
			C466.85,302.122,446.694,319.368,425.353,323.131z"/>
	</g>
</g>
</svg>
  );
}

export default SvgComponent;
